import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BundleService {

    appBaseURL: string;

    constructor(private http: HttpClient) {
        this.appBaseURL = document.location.origin + "/";
        var index = this.appBaseURL.indexOf('localhost:4200', 0);
        if (index > -1) {
            this.appBaseURL = "http://localhost:3000/";
        }
    }
   
    GetAllByUserId(id): Observable<any> {
        return this.http.post(this.appBaseURL + 'getallbundlesbyuserid/' + id, "");
    }
    get(id): Observable<any> {
        return this.http.post(this.appBaseURL +'getbundlesid/'+id,"");
      }
      //getbycode(data): Observable<any> {
      //  return this.http.post(this.appBaseURL +'SearchSettingsByCode',data);
      //}
      
      update(id,data): Observable<any> {
        
        return this.http.post(this.appBaseURL + 'updatebundles/'+id,data);
      }
      create(data): Observable<any> {
        return this.http.post(this.appBaseURL +'addbundles/', data);
      }
      delete(id): Observable<any> {
        return this.http.post(this.appBaseURL +'deletebundles/'+id,"");
      }
      
}
