import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-viewuserlogs',
  templateUrl: './viewuserlogs.component.html',
  styleUrls: ['./viewuserlogs.component.sass']
})
export class ViewuserlogsComponent implements OnInit {

  constructor(private SpinnerService: NgxSpinnerService,private route: ActivatedRoute,
    private router: Router,private toastr: ToastrService,private userservice: UserService) { }
  orders: any[];
  globalid:string;
  ngOnInit() {
    //debugger;
    //check login
    //debugger;
    let userid = localStorage.getItem("user-id");
    this.globalid= userid;

    if (userid == null || userid == '') {
        this.router.navigate(['/pages/login']);
    }
    this.userservice.getalluserlogs(userid).subscribe((data: any) => {
        this.orders = data;
    })
    
}
deleteuserlog(id): void {
  if(confirm("Are you sure to delete All user "+id+" logs")) {
      this.SpinnerService.show();
  this.userservice.deleteuserlogs(id)
    .subscribe(
      response => {
        this.SpinnerService.hide();
        this.toastr.success('Userlogs deleted successfully!', 'Success!');
        this.ngOnInit();
      },
      error => {
        console.log(error);
        this.toastr.error('Some error occurred!', 'Error!');
      });
      
    
    }
    else
    {
      this.SpinnerService.hide();
      this.router.navigate(['/viewuserlogs']);
    }
  
}


}
