import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SettingService {

    appBaseURL: string;

    constructor(private http: HttpClient) {
        this.appBaseURL = document.location.origin + "/";
        var index = this.appBaseURL.indexOf('localhost:4200', 0);
        if (index > -1) {
            this.appBaseURL = "http://localhost:3000/";
        }
    }
    GetAllSettings(data): Observable<any> {
        return this.http.post(this.appBaseURL + 'GetAllSettings', data);
    }
      get(id): Observable<any> {
        return this.http.post(this.appBaseURL +'GetSettingsById/'+id,"");
      }
      getbycode(data): Observable<any> {
        return this.http.post(this.appBaseURL +'SearchSettingsByCode',data);
      }
      update(id,data): Observable<any> {
        return this.http.post(this.appBaseURL + 'UpdateSettings/'+id,data);
      }
      create(data): Observable<any> {
        return this.http.post(this.appBaseURL +'AddSettings/', data);
      }
}
