import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../services/user.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SettingService } from '../../../services/settings.service';
@Component({
  selector: 'app-login',
    templateUrl: './login.component.html'
    //,  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {


    public form: FormGroup;
    submitted = false;
    websiteName: string;
    websitecopyrighttext: SafeHtml;
    constructor(private settingService: SettingService, private sanitizer: DomSanitizer,private userservice: UserService,private formBuilder: FormBuilder, private router: Router, private http: HttpClient, private toastr: ToastrService) {
    }
    ngOnInit() {
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
        //debugger;
        //console.log(this.form);
        const websiteName = {"code": "website-name"}
        this.settingService.getbycode(websiteName).subscribe((data: any) => {
            //debugger;
            localStorage.setItem("website-name", data[0].message);
            this.websiteName = data[0].message;
        })
        const websitecopyrighttext = {
            "code": "website-copyright-text"
        }
        this.settingService.getbycode(websitecopyrighttext).subscribe((data: any) => {
            //debugger;
            this.websitecopyrighttext = this.sanitizer.bypassSecurityTrustHtml(data[0].message + ' - ' + this.websiteName)
        })
    }
    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        if (this.form.invalid) {
            return;
        }
        else {
            const logindata = {
                email: this.form.controls.email.value,
                password: this.form.controls.password.value
            }
            this.userservice.userlogin(logindata).subscribe((data: any) => {
                //debugger;
                if (data == "") {
                    this.toastr.error('Invalid login, please try with valid credentials.', 'Error');
                } else {
                    localStorage.setItem("user-id", data[0].id);
                    localStorage.setItem("user-email", data[0].email);
                    localStorage.setItem("first_name", data[0].first_name);
                    localStorage.setItem("last_name", data[0].last_name);
                    localStorage.setItem("guid", data[0].guid);
                    localStorage.setItem("role", data[0].role);
                    this.toastr.success('Welcome', 'Success');
                    this.router.navigate(['/dashboard']);
                }
            })
        }
    }
}
