import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BundleItemsService } from '../../../services/bundle-items.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BundleService } from '../../../services/bundle.service';
import { UserService } from '../../../services/user.service';
import { v4 as uuidv4 } from 'uuid';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WalmartService } from 'src/app/services/walmart.service';






@Component({
  selector: 'app-add-bundle-items',
  templateUrl: './add-bundle-items.component.html',
  styleUrls: ['./add-bundle-items.component.sass']
})
export class AddBundleItemsComponent implements OnInit {
  @ViewChild('a1') inputName1; // accessing the reference element
  @ViewChild('a2') inputName2; // accessing the reference element
  @ViewChild('a3') inputName3; // accessing the reference element
  @ViewChild('a4') inputName4; // accessing the reference element
  @ViewChild('a5') inputName5; // accessing the reference element
  @ViewChild('a6') inputName6; // accessing the reference element
  @ViewChild('a7') inputName7; // accessing the reference element

  currentbundles: any;
  expected_delievery_date = '';
  itemsToSend: any;
  UserData: any;
  submitted = false;
  orders: any;
  AccessToken: any;
  WalmartItem: any;
  bundlesItems: any;


  constructor(private walmartService: WalmartService, private http: HttpClient, private userService: UserService, private bundleService: BundleService, private toastr: ToastrService, private SpinnerService: NgxSpinnerService, private route: ActivatedRoute, private bundleItemsService: BundleItemsService, private router: Router) { }
  ngOnInit() {
    let userid = localStorage.getItem('user-id');
    if (userid == null || userid == '') {
      this.router.navigate(['/pages/login']);
    }
    this.bundlesItems = {
      user_id: '',
      bundle_id: '',
      product_type: '',
      product_id: '',
      product_sku: '',
      product_description: '',
      item_qty: '',
      vendor_qty: '',
      inner_qty: '',
    };
    this.submitted = false;
    this.expected_delievery_date = '';
    //this.today=new Date().toISOString().split('T')[0];
    this.getSetting(this.route.snapshot.paramMap.get('id'));
    this.getOrderID(this.route.snapshot.paramMap.get('id'));
    this.getBundleItems(this.route.snapshot.paramMap.get('id'));

    //Calling Generate Token
    this.userService.getusersbyid(userid).subscribe(data => {
      this.userService.UserData = data;
      if (this.userService.UserData.Client_ID != '' && this.userService.UserData.Secret_ID != '') {
        const clientid = this.userService.UserData.Client_ID;
        const secretid = this.userService.UserData.Secret_ID;
        this.userService.Authorization = "Basic " + btoa(clientid + ":" + secretid);
        this.GenerateToken();
      }
      else {
        if (this.userService.UserData.Client_ID == '' || this.userService.UserData.Secret_ID == '')
          this.toastr.error('<b>Client_ID</b> or <b>Secret_ID is missing<br>Kindly Go to <b>My Profile</b>', 'Error!', {
            enableHtml: true
          });
      }
    })
    //OLD METHOD
    //     this.userService.getusersbyid(userid).subscribe(data => {
    //       this.orders = data;
    //     })
    //     const headers = new HttpHeaders({ 'WM_SVC.NAME': 'My API Key', 'WM_QOS.CORRELATION_ID': uuidv4(), 'Authorization':'Basic MDIzYTFhOWMtODY5Ni00OWJiLWExMWEtMTU4ODMyMDgwYzRhOlVqR0FEMDNmT1FhN1c0ZFpnRUlGTTB6M25fYUZULU84RzJqRFFLalV1eXZ5ZEZ4bWs4dlFpMjYyNzQyZnJTZ1VmTXFLQjJyWHBFV055ZFFHNUpqdHBB', 'Content-Type': 'application/x-www-form-urlencoded' });
    //       const options = ({headers: headers});
    //       const body = 'grant_type=client_credentials';
    //       this.http.post<any>('https://marketplace.walmartapis.com/v3/token', body, options).subscribe(data => {
    //       return this.AccessToken = data.access_token;
    // });
  }

  GenerateToken() {
    const headers = {
      'NAME': 'My API Key',
      'CORRELATION_ID': uuidv4(),
      'Authorization': this.userService.Authorization,
      'Accept': 'application/json'
    };
    const ApiData = {
      path: 'https://marketplace.walmartapis.com/v3/token',
      headers: headers,
      body: 'grant_type=client_credentials'
    }
    this.walmartService.GenerateToken(ApiData).subscribe(data => {
      debugger;
      this.userService.AccessToken = data;
    },
      error => {
        console.log(error);
        this.toastr.error('Some error occurred getting access token!', 'Error!');
      });
  }
  handleClear() {
    // clearing the value
    this.inputName1.nativeElement.value = '';
    this.inputName2.nativeElement.value = '';
    this.inputName3.nativeElement.value = '';
    this.inputName4.nativeElement.value = '';
    this.inputName5.nativeElement.value = '';
    this.inputName6.nativeElement.value = '';
    this.inputName7.nativeElement.value = '';

  }
  onEnter(value: string) {
    if (value) {
      const headers = {
        'NAME': 'My API Key',
        'CORRELATION_ID': uuidv4(),
        'ACCESS_TOKEN': this.userService.AccessToken,
        'Authorization': this.userService.Authorization,
        'Accept': 'application/json'
      };
      const ApiData = {
        path : 'https://marketplace.walmartapis.com/v3/items/walmart/search?upc='+value,
        headers : headers,
      }
      this.walmartService.WalmartSearchAnitem(ApiData).subscribe(data => {
      this.WalmartItem = data.items[0];
      this.bundlesItems = {

        product_type: 'UPC',
        product_id: value,
        product_sku: value,
        product_description: this.WalmartItem.title,
      };
      //OLd Method
      // const headers1 = new HttpHeaders({ 'WM_SVC.NAME': 'My API Key', 'WM_QOS.CORRELATION_ID': uuidv4(), 'Authorization':'Basic MDIzYTFhOWMtODY5Ni00OWJiLWExMWEtMTU4ODMyMDgwYzRhOlVqR0FEMDNmT1FhN1c0ZFpnRUlGTTB6M25fYUZULU84RzJqRFFLalV1eXZ5ZEZ4bWs4dlFpMjYyNzQyZnJTZ1VmTXFLQjJyWHBFV055ZFFHNUpqdHBB', 'WM_SEC.ACCESS_TOKEN' : this.AccessToken , 'Content-Type': 'application/json' });
      // const options1 = ({headers: headers1});
      // this.http.get<any>('https://marketplace.walmartapis.com/v3/items/walmart/search?upc='+value, options1).subscribe(data => {
      //OLd Method
    });
  }
  else
    {
  this.toastr.error('UPC field Empty!', 'Error!');
}   
    
  }
getBundleItems(id) {
  this.bundleItemsService.GetAllItemsByBundleId(id)
    .subscribe(

      data => {
        this.SpinnerService.hide();
        this.itemsToSend = data;
      },
      error => {
        console.log(error);
      });

  //this.itemsToSend
}
deleteRow(id): void {
  if(confirm("Are you sure to delete " + id)) {
  this.SpinnerService.show();
  this.bundleItemsService.delete(id)
    .subscribe(
      response => {
        this.SpinnerService.hide();
        this.toastr.success('Item deleted successfully!', 'Success!');
        this.ngOnInit();
      },
      error => {
        console.log(error);
        this.toastr.error('Some error occurred!', 'Error!');
      });


}
    else
{
  this.SpinnerService.hide();
  this.router.navigate(['/my-orders']);
}
  
}
getOrderID(id) {
  this.bundleItemsService.getbundleorder(id)
    .subscribe(

      data => {

        this.SpinnerService.hide();
        this.UserData = data;

      },
      error => {
        console.log(error);
      });

  //this.itemsToSend
}
getSetting(id): void {
  this.SpinnerService.show();
  this.bundleService.get(id)
    .subscribe(
      data => {
        this.SpinnerService.hide();
        this.currentbundles = data;
        //const date_format = new Date(this.currentbundles.expected_delievery_date).toLocaleDateString('en-CA');
        //this.currentbundles.expected_delievery_date=date_format;
      },
      error => {
        console.log(error);
      });
}
updateBundles(id, currentbundles): void {
  this.SpinnerService.show();
  let updatedAt = localStorage.getItem('user-id');
  this.currentbundles.updatedAt = updatedAt;
  this.bundleService.update(id, currentbundles)
    .subscribe(
      response => {
        this.SpinnerService.hide();
        this.submitted = true;
        this.toastr.success('Item expected delivery date updated successfully!', 'Success!');
        this.ngOnInit();
      },
      error => {
        console.log(error);
        this.toastr.error('Some error occurred!', 'Error!');
      });
}
saveBundlesItems(): void {
  const data = {
    user_id: localStorage.getItem("user-id"),
    bundle_id: this.UserData[0].bid,
    product_type: this.bundlesItems.product_type,
    product_id: this.bundlesItems.product_id,
    product_sku: this.bundlesItems.product_sku,
    product_description: this.bundlesItems.product_description,
    item_qty: this.bundlesItems.item_qty,
    vendor_qty: this.bundlesItems.vendor_qty,
    inner_qty: this.bundlesItems.inner_qty,
  };
  this.SpinnerService.show();
  this.bundleItemsService.create(data)
    .subscribe(
      response => {
        this.SpinnerService.hide();
        this.submitted = true;
        this.toastr.success('Order item added successfully!', 'Success!');
        this.ngOnInit();
        this.handleClear();
      },
      error => {
        this.SpinnerService.hide();
        console.log(error);
        this.toastr.error('Some error occurred!', 'Error!');
      });
}
}