import { Component, OnInit } from '@angular/core';
import { BundleService } from '../../services/bundle.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.sass']
})
export class MyOrdersComponent implements OnInit {

  constructor(private SpinnerService: NgxSpinnerService, private route: ActivatedRoute,
    private router: Router, private toastr: ToastrService, private bundleService: BundleService) { }

  heading = 'My Inbound Shipments';
  subheading = 'All your Walmart inbound shipments can be configured from here.';
  icon = 'pe-7s-photo-gallery icon-gradient bg-happy-itmeo';
  orders: any[];

  ngOnInit(): void {
    let userid = localStorage.getItem('user-id');
    if (userid == null || userid == '') {
      this.router.navigate(['/pages/login']);
    }
    this.bundleService.GetAllByUserId(userid).subscribe((data: any) => {
      this.orders = data;
    },
    error => {
      console.log(error);
      this.toastr.error('Some error occurred getting shipments!', 'Error!');
    });
  }
  deleteRow(id): void {
    if (confirm("Are you sure to delete " + id)) {
      this.SpinnerService.show();
      this.bundleService.delete(id)
        .subscribe(
          response => {
            this.SpinnerService.hide();
            this.toastr.success('Shipment deleted successfully!', 'Success!');
            this.ngOnInit();
          },
          error => {
            console.log(error);
            this.toastr.error('Some error occurred!', 'Error!');
          });


    }
    else {
      this.SpinnerService.hide();
      this.router.navigate(['/my-orders']);
    }

  }

}
