import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WfsrequiredService } from '../../services/wfsrequired.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-wfsrequired',
  templateUrl: './wfsrequired.component.html',
  styleUrls: ['./wfsrequired.component.sass']
})
export class WfsrequiredComponent implements OnInit {

  constructor(private SpinnerService: NgxSpinnerService,private WfsRequired: WfsrequiredService, private route: ActivatedRoute,
    private router: Router,private toastr: ToastrService) { }
  heading = 'WFS Required';
  subheading = 'All the WFS Required data used in the application can be configured from here.';
  icon = 'pe-7s-helm icon-gradient bg-happy-itmeo';
  wfsrequireddata: any[];

    ngOnInit(): void {
      let userid = localStorage.getItem('user-id');
        if (userid == null || userid == '') {
            this.router.navigate(['/pages/login']);
        }
        this.WfsRequired.getallwfsrequired(this.wfsrequireddata).subscribe((data: any) => {
            this.wfsrequireddata=data;
        })
    }

}