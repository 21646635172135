import { Component, OnInit } from '@angular/core';
import { SettingService } from '../../services/settings.service';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.sass']
})
export class SettingsComponent implements OnInit {

    constructor(private settingService: SettingService) { }

    heading = 'Settings';
    subheading = 'All the setting used in the application can be configured from here.';
    icon = 'pe-7s-helm icon-gradient bg-happy-itmeo';
    settings: any[];

    ngOnInit(): void {
        this.settingService.GetAllSettings(this.settings).subscribe((data: any) => {
            this.settings=data;
        })
    }

}
