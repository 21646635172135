import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as xlsx from 'xlsx';
import { ActivatedRoute } from '@angular/router';
import { BundleItemsService } from '../../../services/bundle-items.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-downloadexcel',
  templateUrl: './downloadexcel.component.html',
  styleUrls: ['./downloadexcel.component.sass']
})
export class DownloadexcelComponent implements OnInit {
    @ViewChild('epltable', { static: false }) epltable: ElementRef;
    bundleId: string;
    itemsToSend: any;
    UserData: any;
    
    constructor(private SpinnerService: NgxSpinnerService, private router: ActivatedRoute, private bundleItemsService: BundleItemsService) { }
    ngOnInit() {
        this.getOrderID(this.router.snapshot.paramMap.get('id'));
      this.getBundleItems(this.router.snapshot.paramMap.get('id'));
    }
    getBundleItems(id) {
        this.bundleItemsService.GetAllItemsByBundleId(id)
            .subscribe(
                
                data => {
                    this.SpinnerService.hide();
                    this.itemsToSend = data;
      
                },
                error => {
                    console.log(error);
                });
        
        //this.itemsToSend
      }
      getOrderID(id) {
            this.bundleItemsService.getbundleorder(id)
                .subscribe(
                    
                    data => {
      
                        this.SpinnerService.hide();
                        this.UserData = data;
      
                    },
                    error => {
                        console.log(error);
                    });
            
            //this.itemsToSend
        }
    exportToExcel() {
        let nUserId = localStorage.getItem("user-id");
        this.bundleItemsService.StoreUserLogs(nUserId).subscribe();
        // const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(this.epltable.nativeElement);
        const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(this.epltable.nativeElement, {dateNF:'yyyy-MM-dd;@',cellDates:true });
        const wb: xlsx.WorkBook = xlsx.utils.book_new(); 
        xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
        xlsx.writeFile(wb, this.UserData[0].inbound_order_number+'.xlsx');
        //xlsx.writeFile(wb, 'epltable.csv');
    }
}