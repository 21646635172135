import { Component, OnInit } from '@angular/core';
import { SettingService } from './services/settings.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent {
  title = 'ArchitectUI - Angular 7 Bootstrap 4 & Material Design Admin Dashboard Template';
  websiteTitle: string ;
  websitefavicon: string ;

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
    constructor(private titleService: Title, private settingService: SettingService, private router: Router) { }
  ngOnInit() {
    const websiteTitle = {code: "website-title"}
    this.settingService.getbycode(websiteTitle).subscribe((data: any) => {
      //debugger;
      
          localStorage.setItem("wwebsite-title", data[0].message);
          this.websiteTitle=data[0].message;
          this.titleService.setTitle(this.websiteTitle);
    })

    const websitefavicon = {code: "website-favicon"}
    this.settingService.getbycode(websitefavicon).subscribe((data: any) => {
    this.websitefavicon=data[0].message;
        this.favIcon.href = this.websitefavicon;
            //this.router.navigate(['/dashboard/']);
  })

}
}
