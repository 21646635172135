import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../../services/user.service';
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.sass']
})
export class MyProfileComponent  implements OnInit {
  submitted = false;
  orders: any;
  
  constructor(private toastr: ToastrService,private SpinnerService: NgxSpinnerService, private route: ActivatedRoute, private userService: UserService,private router: Router) { }

  ngOnInit(): void {
   
    let nUserId = localStorage.getItem("user-id");
    this.userService.getusersbyid(nUserId).subscribe(data => {
        this.orders = data;

    })
  }
  updateProfileData(id, orders): void {
    this.SpinnerService.show();
    this.userService.update(id,orders)
      .subscribe(
        response => {
          this.SpinnerService.hide();
          this.router.navigate(['/my-profile']);
          this.toastr.success('Profile updated successfully!', 'Success!');
          this.ngOnInit();
        },
        error => {
          console.log(error);
          this.toastr.error('Some error occurred!', 'Error!');
        });
  }


}