import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BundleService } from '../../../../services/bundle.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-edit-bundles',
  templateUrl: './edit-bundles.component.html',
  styleUrls: ['./edit-bundles.component.sass']
})
export class EditBundlesComponent implements OnInit {
  submitted = false;
  currentbundles: any;
  expected_delievery_date = '';


  constructor(private SpinnerService: NgxSpinnerService, private bundleService: BundleService, private route: ActivatedRoute,
    private router: Router,private toastr: ToastrService) { }

  ngOnInit(): void {
    let userid = localStorage.getItem('user-id');
        if (userid == null || userid == '') {
            this.router.navigate(['/pages/login']);
        }
    this.expected_delievery_date = '';
    this.getSetting(this.route.snapshot.paramMap.get('id'));
    
  }

  getSetting(id): void {
    this.SpinnerService.show();
    this.bundleService.get(id)
      .subscribe(
        data => {
          this.SpinnerService.hide();
          this.currentbundles = data;
        },
        error => {
          console.log(error);
        });
  }
  updateBundles(id, currentbundles): void {
    this.SpinnerService.show();
    let updatedAt = localStorage.getItem('user-id');
    this.currentbundles.updatedAt=updatedAt;
    this.bundleService.update(id,currentbundles)
      .subscribe(
        response => {
          this.SpinnerService.hide();
          this.router.navigate(['/my-orders']);
          this.toastr.success('Order updated successfully!', 'Success!');
        },
        error => {
          console.log(error);
          this.toastr.error('Some error occurred!', 'Error!');
        });
  }
}
