import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
    appBaseURL: string;
    UserData:any;
    Authorization:any;
    AccessToken:any;
    constructor(private http: HttpClient) {
        this.appBaseURL = document.location.origin + "/";
        var index = this.appBaseURL.indexOf('localhost:4200', 0);
        if (index > -1) {
            this.appBaseURL = "http://localhost:3000/";
        }
    }
    getalluserlogs(id): Observable<any> {
        return this.http.post(this.appBaseURL + 'getalluserlogs/'+id,"");
    }
    getuserlogs(id): Observable<any> {
        return this.http.post(this.appBaseURL + 'getuserlogs/'+id,"");
    }
    getusersbyid(id): Observable<any> {     
        return this.http.post(this.appBaseURL + 'getusersbyid/'+id,"");
    }
    topstats(id): Observable<any> {
        return this.http.post(this.appBaseURL + 'topstats/'+id,"");
    }
   
    userlogin(data): Observable<any> {
        return this.http.post(this.appBaseURL + 'UserLogin', data);
    }
    ChangePassword(data): Observable<any> {
        return this.http.post(this.appBaseURL + 'ChangePassword', data);
    }
    forgetpassword(fordata): Observable<any> {
        return this.http.post(this.appBaseURL + 'ForgotPassword', fordata);
    }
    resetpassword(redata): Observable<any> {
        return this.http.post(this.appBaseURL + 'ResetPassword', redata);
    }
    update(id,data): Observable<any> {
        
        return this.http.post(this.appBaseURL + 'updateuser/'+id,data);
    }
    deleteuserlogs(id): Observable<any> {
        return this.http.post(this.appBaseURL +'deleteuserlogs/'+id,"");
    }
    addusers(data): Observable<any> {
        return this.http.post(this.appBaseURL + 'addusers', data);
    }
    

}
