import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class WfsrequiredService {
  appBaseURL: string;
  constructor(private http: HttpClient) {
      this.appBaseURL = document.location.origin + "/";
      var index = this.appBaseURL.indexOf('localhost:4200', 0);
      if (index > -1) {
          this.appBaseURL = "http://localhost:3000/";
      }
  }
  getallwfsrequired(data): Observable<any> {
    return this.http.post(this.appBaseURL + 'getallwfsrequired', data);
}
  get(id): Observable<any> {
    return this.http.post(this.appBaseURL +'getwfsrequiredbyid/'+id,"");
  }
  update(data): Observable<any> {
        
    return this.http.post(this.appBaseURL + 'updatewfsrequired/',data);
  }

}
