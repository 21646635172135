import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgReduxModule} from '@angular-redux/store';
import {NgRedux, DevToolsExtension} from '@angular-redux/store';
import {rootReducer, ArchitectUIState} from './ThemeOptions/store';
import {ConfigActions} from './ThemeOptions/store/config.actions';
import {AppRoutingModule} from './app-routing.module';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
// SpreadSheet
// import { DropDownListAllModule, MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
// import { RadioButtonAllModule, ButtonAllModule } from '@syncfusion/ej2-angular-buttons';
// import { TextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
// import { SpreadsheetAllModule } from '@syncfusion/ej2-angular-spreadsheet';

// BOOTSTRAP COMPONENTS

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {ChartsModule} from 'ng2-charts';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// LAYOUT

import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import {PagesLayoutComponent} from './Layout/pages-layout/pages-layout.component';
import {PageTitleComponent} from './Layout/Components/page-title/page-title.component';

// HEADER

import {HeaderComponent} from './Layout/Components/header/header.component';
import {SearchBoxComponent} from './Layout/Components/header/elements/search-box/search-box.component';
import {UserBoxComponent} from './Layout/Components/header/elements/user-box/user-box.component';

// SIDEBAR

import {SidebarComponent} from './Layout/Components/sidebar/sidebar.component';
import {LogoComponent} from './Layout/Components/sidebar/elements/logo/logo.component';

// FOOTER

import {FooterComponent} from './Layout/Components/footer/footer.component';

// DEMO PAGES

// Dashboards

import {AnalyticsComponent} from './DemoPages/Dashboards/analytics/analytics.component';

// Pages

import {ForgotPasswordComponent} from './DemoPages/UserPages/forgot-password/forgot-password.component';
import {RegisterComponent} from './DemoPages/UserPages/register/register.component';

// Elements

import {StandardComponent} from './DemoPages/Elements/Buttons/standard/standard.component';
import {DropdownsComponent} from './DemoPages/Elements/dropdowns/dropdowns.component';
import {CardsComponent} from './DemoPages/Elements/cards/cards.component';
import {ListGroupsComponent} from './DemoPages/Elements/list-groups/list-groups.component';
import {TimelineComponent} from './DemoPages/Elements/timeline/timeline.component';
import {IconsComponent} from './DemoPages/Elements/icons/icons.component';

// Components

import {AccordionsComponent} from './DemoPages/Components/accordions/accordions.component';
import {TabsComponent} from './DemoPages/Components/tabs/tabs.component';
import {CarouselComponent} from './DemoPages/Components/carousel/carousel.component';
import {ModalsComponent} from './DemoPages/Components/modals/modals.component';
import {ProgressBarComponent} from './DemoPages/Components/progress-bar/progress-bar.component';
import {PaginationComponent} from './DemoPages/Components/pagination/pagination.component';
import {TooltipsPopoversComponent} from './DemoPages/Components/tooltips-popovers/tooltips-popovers.component';

// Tables

import {RegularComponent} from './DemoPages/Tables/regular/regular.component';
import {TablesMainComponent} from './DemoPages/Tables/tables-main/tables-main.component';

// Widgets

import {ChartBoxes3Component} from './DemoPages/Widgets/chart-boxes3/chart-boxes3.component';

// Forms Elements

import {ControlsComponent} from './DemoPages/Forms/Elements/controls/controls.component';
import {LayoutComponent} from './DemoPages/Forms/Elements/layout/layout.component';

// Charts


// Chart.js Examples

import { ToastrModule } from 'ngx-toastr';
import { ConsultantComponent } from './DemoPages/consultant/consultant.component';

import { ArchwizardModule } from 'angular-archwizard';
import { ResetPasswordComponent } from './DemoPages/UserPages/reset-password/reset-password.component';
import { LoginComponent } from './DemoPages/UserPages/login/login.component';
import { ChangePasswordComponent } from './DemoPages/UserPages/change-password/change-password.component';
import { SettingsComponent } from './Admin/settings/settings.component';
import { AddsettingsComponent } from './Admin/settings/addsettings/addsettings.component';
import { EditsettingsComponent } from './Admin/settings/editsettings/editsettings.component';
import { AdditemComponent } from './DemoPages/UserPages/additem/additem.component';
import { DownloadexcelComponent } from './DemoPages/UserPages/downloadexcel/downloadexcel.component';
import { MyOrdersComponent } from './DemoPages/my-orders/my-orders.component';
import { AddBundlesComponent } from './DemoPages/my-orders/AddBundles/add-bundles/add-bundles.component';
import { EditBundlesComponent } from './DemoPages/my-orders/EditBundles/edit-bundles/edit-bundles.component';
import { AddBundleItemsComponent } from './DemoPages/my-orders/add-bundle-items/add-bundle-items.component';
import { MyProfileComponent } from './DemoPages/UserPages/myprofile/my-profile/my-profile.component';
import { ViewuserlogsComponent } from './DemoPages/UserPages/viewuserlogs/viewuserlogs.component';
import { SearchItemComponent } from './DemoPages/my-orders/SearchItem/search-item.component';
import { AdditemapiComponent } from './DemoPages/my-orders/additemapi/additemapi.component';
import { WfsrequiredComponent } from './Admin/wfsrequired/wfsrequired.component';
import { EditwfsrequiredComponent } from './Admin/wfsrequired/editwfsrequired/editwfsrequired.component';
import { SearchitemsellerComponent } from './DemoPages/my-orders/searchitemseller/searchitemseller.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [

    // LAYOUT

    AppComponent,
    BaseLayoutComponent,
    PagesLayoutComponent,
    PageTitleComponent,

    // HEADER

    HeaderComponent,
    SearchBoxComponent,
    UserBoxComponent,

    // SIDEBAR

    SidebarComponent,
    LogoComponent,

    // FOOTER

    FooterComponent,

    // DEMO PAGES

    // Dashboards

    AnalyticsComponent,

    // User Pages

    ForgotPasswordComponent,
    LoginComponent,
    RegisterComponent,

    // Elements

    StandardComponent,
    IconsComponent,
    DropdownsComponent,
    CardsComponent,
    ListGroupsComponent,
    TimelineComponent,

    // Components

    AccordionsComponent,
    TabsComponent,
    CarouselComponent,
    ModalsComponent,
    ProgressBarComponent,
    PaginationComponent,
    TooltipsPopoversComponent,

    // Tables

    RegularComponent,
    TablesMainComponent,

    // Dashboard Boxes

    ChartBoxes3Component,

    // Form Elements

    ControlsComponent,
    LayoutComponent,

        ConsultantComponent,
         ResetPasswordComponent,
         ChangePasswordComponent,
         SettingsComponent,
         AddsettingsComponent,
         EditsettingsComponent,
         AdditemComponent,
         DownloadexcelComponent,
         MyOrdersComponent,
         AddBundlesComponent,
         EditBundlesComponent,
         AddBundleItemsComponent,
         MyProfileComponent,
         ViewuserlogsComponent,
         SearchItemComponent,
         AdditemapiComponent,
         WfsrequiredComponent,
         EditwfsrequiredComponent,
         SearchitemsellerComponent,
  ],
  imports: [
    BrowserModule,
    NgSelectModule,
    AppRoutingModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,
    NgxSpinnerModule,
    AngularEditorModule,
    NgxJsonViewerModule,
    // SpreadsheetAllModule,
    // TextBoxAllModule,
    // RadioButtonAllModule,
    // DropDownListAllModule,
    // MultiSelectAllModule,
    // ButtonAllModule,
    // Angular Bootstrap Components

    PerfectScrollbarModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    // Charts

      ChartsModule,
      BrowserAnimationsModule, // required animations module
      ToastrModule.forRoot(), // ToastrModule added
      ArchwizardModule,

      
  ],
  providers: [
    {
      provide:
      PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
      DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    ConfigActions,
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
              private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}
