import {Component, HostListener, OnInit} from '@angular/core';
import {ThemeOptions} from '../../../theme-options';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import { SettingService } from '../../../services/settings.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
    websiteLogo: string;
    isLoggedInUser: boolean;
    isAdminUser: boolean;
  public extraParameter: any;

    constructor(private router: Router,private settingService: SettingService,public globals: ThemeOptions, private activatedRoute: ActivatedRoute) {
        this.isLoggedInUser = false;
        this.isAdminUser = false;
  }

  @select('config') public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboardsMenu';

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }
    onClick($event) {
        localStorage.clear();
        let userid = localStorage.getItem('user-id');
        if (userid == null || userid == '') {
            this.router.navigate(['/pages/login']);
        }
    }   
  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

    ngOnInit() {
        let userid = localStorage.getItem('user-id');
        if (userid == null || userid == '') {
            this.router.navigate(['/pages/login']);
        } else {
            
            this.isLoggedInUser = true;
            this.isAdminUser = localStorage.getItem('role')=="user"?false:true;
        }
    const websiteLogo = {
      code: "website-logo"
  }
  this.settingService.getbycode(websiteLogo).subscribe((data: any) => {
    
        localStorage.setItem("website-logo", data[0].message);
        this.websiteLogo = data[0].message;
})
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    this.extraParameter = this.activatedRoute.snapshot.firstChild.data.extraParameter;

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }
}
