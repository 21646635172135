import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BundleItemsService } from '../../../services/bundle-items.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BundleService } from '../../../services/bundle.service';
import { UserService } from '../../../services/user.service';
import { v4 as uuidv4 } from 'uuid';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WalmartService } from 'src/app/services/walmart.service';
@Component({
  selector: 'app-searchitemseller',
  templateUrl: './searchitemseller.component.html',
  styleUrls: ['./searchitemseller.component.sass']
})
export class SearchitemsellerComponent implements OnInit {
  AccessToken: any;
  WalmartItem: any;
  submitted = false;
  inputcheck: any;
  constructor(private walmartService: WalmartService, private http: HttpClient, private userService: UserService, private bundleService: BundleService, private toastr: ToastrService, private SpinnerService: NgxSpinnerService, private route: ActivatedRoute, private bundleItemsService: BundleItemsService, private router: Router) { }


  ngOnInit() {
    this.submitted = false;
    let userid = localStorage.getItem('user-id');
    if (userid == null || userid == '') {
      this.router.navigate(['/pages/login']);
    }
    //Calling Generate Token
    this.userService.getusersbyid(userid).subscribe(data => {
      this.userService.UserData = data;
      if (this.userService.UserData.Client_ID != '' && this.userService.UserData.Secret_ID != '') {
        const clientid = this.userService.UserData.Client_ID;
        const secretid = this.userService.UserData.Secret_ID;
        this.userService.Authorization = "Basic " + btoa(clientid + ":" + secretid);
        this.GenerateToken();
      }
      else {
        if (this.userService.UserData.Client_ID == '' || this.userService.UserData.Secret_ID == '')
          this.toastr.error('<b>Client_ID</b> or <b>Secret_ID is missing<br>Kindly Go to <b>My Profile</b>', 'Error!', {
            enableHtml: true
          });
      }
    })
    //OLD METHOD
    //     this.userService.getusersbyid(userid).subscribe(data => {
    //       this.orders = data;
    //     })
    //     const headers = new HttpHeaders({ 'WM_SVC.NAME': 'My API Key', 'WM_QOS.CORRELATION_ID': uuidv4(), 'Authorization':'Basic MDIzYTFhOWMtODY5Ni00OWJiLWExMWEtMTU4ODMyMDgwYzRhOlVqR0FEMDNmT1FhN1c0ZFpnRUlGTTB6M25fYUZULU84RzJqRFFLalV1eXZ5ZEZ4bWs4dlFpMjYyNzQyZnJTZ1VmTXFLQjJyWHBFV055ZFFHNUpqdHBB', 'Content-Type': 'application/x-www-form-urlencoded' });
    //       const options = ({headers: headers});
    //       const body = 'grant_type=client_credentials';
    //       this.http.post<any>('https://marketplace.walmartapis.com/v3/token', body, options).subscribe(data => {
    //       return this.AccessToken = data.access_token;
    // });
  }
  GenerateToken() {
    const headers = {
      'NAME': 'My API Key',
      'CORRELATION_ID': uuidv4(),
      'Authorization': this.userService.Authorization,
      'Accept': 'application/json'
    };
    const ApiData = {
      path: 'https://marketplace.walmartapis.com/v3/token',
      headers: headers,
      body: 'grant_type=client_credentials'
    }
    this.walmartService.GenerateToken(ApiData).subscribe(data => {
      debugger;
      this.userService.AccessToken = data;
    },
      error => {
        console.log(error);
        this.toastr.error('Some error occurred getting access token!', 'Error!');
      });
  }
  onEnter(value: string) {
    if (value) {
      debugger;
      const headers = {
        'NAME': 'My API Key',
        'CORRELATION_ID': uuidv4(),
        'ACCESS_TOKEN': this.userService.AccessToken,
        'Authorization': this.userService.Authorization,
        'Accept': 'application/json'
      };
      const ApiData = {
        path : 'https://marketplace.walmartapis.com/v3/items/walmart/search?upc='+value,
        headers : headers,
      }
      this.walmartService.WalmartSearchAnitem(ApiData).subscribe(data => {
      debugger;
      this.WalmartItem = data;
      this.inputcheck = value;
      
      //OLD Method
      // const headers1 = new HttpHeaders({ 'WM_SVC.NAME': 'My API Key', 'WM_QOS.CORRELATION_ID': uuidv4(), 'Authorization': 'Basic MDIzYTFhOWMtODY5Ni00OWJiLWExMWEtMTU4ODMyMDgwYzRhOlVqR0FEMDNmT1FhN1c0ZFpnRUlGTTB6M25fYUZULU84RzJqRFFLalV1eXZ5ZEZ4bWs4dlFpMjYyNzQyZnJTZ1VmTXFLQjJyWHBFV055ZFFHNUpqdHBB', 'WM_SEC.ACCESS_TOKEN': this.AccessToken, 'Content-Type': 'application/json', 'Accept': 'application/json' });
      // const options1 = ({ headers: headers1 });
      // this.http.get<any>('https://marketplace.walmartapis.com/v3/items/' + value, options1).subscribe(data => {
      //Old Method
    },
    error => {
      console.log(error);
      this.toastr.error('No Items found for the input parameters!', 'Error!');
    });
  }
  else {
  this.toastr.error('UPC field Empty!', 'Error!');
}

}
  

}
