import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styles: []
})
export class RegisterComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  constructor(private userservice: UserService,private route: ActivatedRoute, private formBuilder: FormBuilder, private router: Router, private http: HttpClient, private toastr: ToastrService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
     
  });
    this.form = this.formBuilder.group({
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        email: ['', [Validators.email,Validators.required]],
        password2: ['', [Validators.required, Validators.minLength(6)]],
        password: ['', [Validators.required, Validators.minLength(6)]]
    });
}
  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  onSubmit() {
    //debugger;
    this.submitted = true;
    if (this.form.invalid || this.form.controls.password.value != this.form.controls.password2.value) {
        this.toastr.error('Password, invalid.', 'Error');
        return;
    }
    else {
        const registeruser = {
            email: this.form.controls.email.value,
            password: this.form.controls.password.value,
            first_name: this.form.controls.first_name.value,
            last_name: this.form.controls.last_name.value
            
        }
        this.userservice.addusers(registeruser).subscribe((data: any) => {
           // debugger;
            if (registeruser.email == data || data == null) {
                this.toastr.error(`Email '`+registeruser.email+`' already registered, please try with valid credentials.`, 'Error');
                return;
            } else {     
                this.toastr.success(`User '`+data+`' registered, successfully.`, 'Success');
                this.router.navigate(['/pages/login']);
            }
        })
    }
}
}
