import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router , NavigationEnd, NavigationStart } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
  styles: []
})
export class ForgotPasswordComponent implements OnInit {
    public form: FormGroup;
    public Email = '';
    submitted = false;
    appBaseURL: string;

    constructor(private userservice: UserService, private formBuilder: FormBuilder, private router: Router, private http: HttpClient, private toastr: ToastrService) {
    }

    ngOnInit() {
        this.appBaseURL = document.location.origin + "/";
        var index = this.appBaseURL.indexOf('localhost:4200', 0);
        if (index > -1) {
            this.appBaseURL = "http://localhost:3000/";
        }
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }
    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        if (this.form.invalid) {
            return;
        }
        else {
            const forgetdata = {
                email: this.form.value.email
            }
            this.userservice.forgetpassword(forgetdata).subscribe((data: any) => {
                //debugger;
                if (data == "" || data == null) {
                    this.toastr.error('Invalid email, please try with valid email.', 'Error');
                } else {
                    this.router.navigate(['/pages/login']);
                    this.toastr.success('We have e-mailed your password reset link!', 'Success');//localStorage.setItem("user-id", data[0].id);
                    //localStorage.setItem("user-email", data[0].email);
                    //localStorage.setItem("first_name", data[0].first_name);
                    //localStorage.setItem("last_name", data[0].last_name);
                    //localStorage.setItem("guid", data[0].guid);
                    //localStorage.setItem("role", data[0].role);
                    //this.toastr.success('Welcome', 'Success');
                    //this.router.navigate(['/dashboard']);
                }
            })
        }
    }
}
