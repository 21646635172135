import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import {PagesLayoutComponent} from './Layout/pages-layout/pages-layout.component';
import {AnalyticsComponent} from './DemoPages/Dashboards/analytics/analytics.component';
import {ForgotPasswordComponent} from './DemoPages/UserPages/forgot-password/forgot-password.component';
import {RegisterComponent} from './DemoPages/UserPages/register/register.component';
import {StandardComponent} from './DemoPages/Elements/Buttons/standard/standard.component';
import {DropdownsComponent} from './DemoPages/Elements/dropdowns/dropdowns.component';
import {CardsComponent} from './DemoPages/Elements/cards/cards.component';
import {ListGroupsComponent} from './DemoPages/Elements/list-groups/list-groups.component';
import {TimelineComponent} from './DemoPages/Elements/timeline/timeline.component';
import {IconsComponent} from './DemoPages/Elements/icons/icons.component';
import {AccordionsComponent} from './DemoPages/Components/accordions/accordions.component';
import {CarouselComponent} from './DemoPages/Components/carousel/carousel.component';
import {ModalsComponent} from './DemoPages/Components/modals/modals.component';
import {ProgressBarComponent} from './DemoPages/Components/progress-bar/progress-bar.component';
import {PaginationComponent} from './DemoPages/Components/pagination/pagination.component';
import {TooltipsPopoversComponent} from './DemoPages/Components/tooltips-popovers/tooltips-popovers.component';
import {TablesMainComponent} from './DemoPages/Tables/tables-main/tables-main.component';
import {ChartBoxes3Component} from './DemoPages/Widgets/chart-boxes3/chart-boxes3.component';
import {ControlsComponent} from './DemoPages/Forms/Elements/controls/controls.component';
import {LayoutComponent} from './DemoPages/Forms/Elements/layout/layout.component';
import { LoginComponent } from './DemoPages/UserPages/login/login.component';
import { ConsultantComponent } from './DemoPages/consultant/consultant.component';
import { ResetPasswordComponent } from './DemoPages/UserPages/reset-password/reset-password.component';
import { ChangePasswordComponent } from './DemoPages/UserPages/change-password/change-password.component';
import { SettingsComponent } from './Admin/settings/settings.component';
import { AddsettingsComponent } from './Admin/settings/addsettings/addsettings.component';
import { EditsettingsComponent } from './Admin/settings/editsettings/editsettings.component';
import { AdditemComponent } from './DemoPages/UserPages/additem/additem.component';
import { DownloadexcelComponent } from './DemoPages/UserPages/downloadexcel/downloadexcel.component';
import { MyOrdersComponent } from './DemoPages/my-orders/my-orders.component';
import { AddBundlesComponent } from './DemoPages/my-orders/AddBundles/add-bundles/add-bundles.component';
import { EditBundlesComponent } from './DemoPages/my-orders/EditBundles/edit-bundles/edit-bundles.component';
import { AddBundleItemsComponent } from './DemoPages/my-orders/add-bundle-items/add-bundle-items.component';
import { MyProfileComponent } from './DemoPages/UserPages/myprofile/my-profile/my-profile.component';
import { ViewuserlogsComponent } from './DemoPages/UserPages/viewuserlogs/viewuserlogs.component';
import { SearchItemComponent } from './DemoPages/my-orders/SearchItem/search-item.component';
import { AdditemapiComponent } from './DemoPages/my-orders/additemapi/additemapi.component';
import { WfsrequiredComponent } from './Admin/wfsrequired/wfsrequired.component';
import { EditwfsrequiredComponent } from './Admin/wfsrequired/editwfsrequired/editwfsrequired.component';
import { SearchitemsellerComponent } from './DemoPages/my-orders/searchitemseller/searchitemseller.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: '',
    component: BaseLayoutComponent,
    children: [

      // Dashboads
      { path: 'dashboard', component: AnalyticsComponent, data: { extraParameter: 'dashboardsMenu' } },
        { path: 'viewuserlogs', component: ViewuserlogsComponent, data: { extraParameter: '' } },      
        { path: 'my-profile', component: MyProfileComponent, data: { extraParameter: '' } },
        { path: 'my-orders', component: MyOrdersComponent, data: { extraParameter: '' } },
        { path: 'addbundles', component: AddBundlesComponent, data: { extraParameter: '' } },
        { path: 'editbundles/:id', component: EditBundlesComponent, data: { extraParameter: '' } },
        { path: 'addbundleitems/:id', component: AddBundleItemsComponent, data: { extraParameter: '' } },
        { path: 'searchitem', component: SearchItemComponent, data: { extraParameter: '' } },
        { path: 'searchitemseller', component: SearchitemsellerComponent, data: { extraParameter: '' } },
        { path: 'additemapi', component: AdditemapiComponent, data: { extraParameter: '' } },
        { path: 'additemapi/:id', component: AdditemapiComponent},
        { path: 'additem', component: AdditemComponent, data: { extraParameter: '' } },
        { path: 'downloadexcel/:id', component: DownloadexcelComponent},

      //Admin
        { path: 'settings', component: SettingsComponent, data: { extraParameter: '' } },
        { path: 'addsettings', component: AddsettingsComponent, data: { extraParameter: '' } },
        { path: 'editsettings/:id', component: EditsettingsComponent },
        { path: 'wfsrequired', component: WfsrequiredComponent, data: { extraParameter: '' } },
        { path: 'editwfsrequired/:id', component: EditwfsrequiredComponent },

        
      // Elements

      {path: 'elements/buttons-standard', component: StandardComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/dropdowns', component: DropdownsComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/icons', component: IconsComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/cards', component: CardsComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/list-group', component: ListGroupsComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/timeline', component: TimelineComponent, data: {extraParameter: 'elementsMenu'}},

      // Components

      // {path: 'components/tabs', component: TabsComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/accordions', component: AccordionsComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/modals', component: ModalsComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/progress-bar', component: ProgressBarComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/tooltips-popovers', component: TooltipsPopoversComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/carousel', component: CarouselComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/pagination', component: PaginationComponent, data: {extraParameter: 'componentsMenu'}},

      // Tables

      {path: 'tables/bootstrap', component: TablesMainComponent, data: {extraParameter: 'tablesMenu'}},

      // Widgets

      {path: 'widgets/chart-boxes-3', component: ChartBoxes3Component, data: {extraParameter: 'pagesMenu3'}},

      // Forms Elements

      {path: 'forms/controls', component: ControlsComponent, data: {extraParameter: 'formElementsMenu'}},
      {path: 'forms/layouts', component: LayoutComponent, data: {extraParameter: 'formElementsMenu'}},

      

    ]

  },
  {
    path: '',
    component: PagesLayoutComponent,
    children: [

      // User Pages
      { path: 'pages/change-password', component: ChangePasswordComponent, data: { extraParameter: '' } },
      { path: 'pages/reset-password', component: ResetPasswordComponent, data: { extraParameter: '' } },
      {path: 'pages/login', component: LoginComponent, data: {extraParameter: ''}},
      {path: 'pages/register', component: RegisterComponent, data: {extraParameter: ''}},
      {path: 'pages/forgot-password', component: ForgotPasswordComponent, data: {extraParameter: ''}},
      { path: 'pages/add-consultant', component: ConsultantComponent, data: {extraParameter: ''}},
    ]
  },
  { path: '**', redirectTo: 'pages/login' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
    useHash: true,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
