import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConsultantService } from '../../services/consultant.service';

@Component({
  selector: 'app-consultant',
  templateUrl: './consultant.component.html',
  styleUrls: ['./consultant.component.scss']
})
export class ConsultantComponent implements OnInit {
    personalDetails!: FormGroup;
    addressDetails!: FormGroup;
    skillsDetails!: FormGroup;
    SpeclizeDetails!: FormGroup;
    personal_step = false;
    address_step = false;
    education_step = false;
    speclize_step = false;
    step = 1;
    getallarea: any;
    areaVals: Array<string> = []; 
    skillVals: Array<string> = []; 
    specializationsVals: Array<string> = []; 
    getallskill: any;
    getallspec: any;
    areaone: any;
    appBaseURL: string;
    getallspec2: any;
    getallspec3: any;
    getallspec4: any;
    getallspec5: any;
    getallspec6: any;
    uploadDetails: FormGroup;
    upload_step = false;
    addconst: any;

    constructor(private consultservice: ConsultantService, private formBuilder: FormBuilder, private httpClient: HttpClient, private router: Router, private toastr: ToastrService) { }

    ngOnInit(): void {
        this.appBaseURL = document.location.origin + "/";
        var index = this.appBaseURL.indexOf('localhost:4200', 0);
        if (index > -1) {
            this.appBaseURL = "http://localhost:3000/";
        }
        const data = {}
        this.consultservice.getallarea(data).subscribe(
            (data:any) => {
                this.getallarea = data;
            },
            error => {
                console.log(error);
            }
        );
        const skilldata = {}
        this.consultservice.getallskill( skilldata).subscribe(
            (data:any) => {
                this.getallskill = data;
            },
            error => {
                console.log(error);
            }
        );
        this.personalDetails = this.formBuilder.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            Totexp: ['', Validators.required],
            drpkr: ['', Validators.required],
            email: ['', Validators.required]
        });
        this.addressDetails = this.formBuilder.group({
            areas: [false, Validators.required]
        });
        this.SpeclizeDetails = this.formBuilder.group({
            skills: [false, Validators.required]
        });
        this.skillsDetails = this.formBuilder.group({
            skills: [false, Validators.required]
        });
        this.uploadDetails = this.formBuilder.group({
        });
        
    }
    checkChecked(id) {
        var index = this.areaVals.indexOf(id, 0);
        if (index > -1) {
            this.areaVals.push(id);
            return true;
        } else
            return false;
    }
    speclizeChecked(id) {
        var index = this.specializationsVals.indexOf(id, 0);
        if (index > -1) {
            this.specializationsVals.push(id);
            return true;
        } else
            return false;
    }
    skillChecked(id) {
        var index = this.skillVals.indexOf(id, 0);
        if (index > -1) {
            this.skillVals.push(id);
            return true;
        } else
            return false;
    }

    eventCheck(id, e) {
        var index = this.areaVals.indexOf(id, 0);
        if (index > -1)
            this.areaVals.splice(index, 1);
        else
            this.areaVals.push(id);
    }
    eventCheckskill(id, e) {
        var index = this.skillVals.indexOf(id, 0);
        if (index > -1)
            this.skillVals.splice(index, 1);
        else
            this.skillVals.push(id);
    }
    eventCheckspeclize(id, e) {
        var index = this.specializationsVals.indexOf(id, 0);
        if (index > -1)
            this.specializationsVals.splice(index, 1);
        else
            this.specializationsVals.push(id);
    }
    GetSpeclize() {
        const specdata = {}
        for (var i = 0; i < this.areaVals.length; i++) {
            const id = this.areaVals[i];
            this.consultservice.getallspeclize(id, specdata).subscribe((data: any) => {
                    if (data.length == 16) {
                        this.getallspec = data;
                    }
                    else if (data.length == 9) {
                        this.getallspec2 = data;
                    }
                    else if (data.length == 5) {
                        this.getallspec3 = data;
                    }
                    else if (data.length == 2) {
                        this.getallspec4 = data;
                    }
                    else if (data.length == 10) {
                        this.getallspec5 = data;
                    }
                    else if (data.length == 3) {
                        this.getallspec6 = data;
                    }
                }),
                    error => {
                        console.log(error);
                    }
            }
    }
    get personal() { return this.personalDetails.controls; }
    get address() { return this.addressDetails.controls; }
    get speclize() { return this.SpeclizeDetails.controls; }
    get skills() { return this.skillsDetails.controls; }
    get uploadcv() { return this.uploadDetails.controls; }

    next() {
        if (this.step == 1) {
            this.personal_step = true;
            if (this.personalDetails.invalid) { return }
            this.step++
        }   
    }
    nextarea() {
        if (this.step == 2) {
            this.address_step = true;
            if (this.addressDetails.invalid) { return }
            this.step++;
        }
    }
    specnext() {
        if (this.step == 3) {
            this.speclize_step = true;
            if (this.SpeclizeDetails.invalid) { return }
            this.step++;
        }
    }
    specuplod() {
        if (this.step == 4) {
            this.upload_step = true;
            if (this.skillsDetails.invalid) { return }
            this.step++;
        }
    }
    previous() {
        this.step--
        if (this.step == 1) {
            this.personal_step = false;
        }
    }
    previousarea() {
        this.step--
        if (this.step == 2) {
            this.address_step = false;
        }
        
    }
    previousspec() {
        this.step--
        if (this.step == 3) {
            this.speclize_step = false;
        }
    }
    Saveinfo() {
        //debugger;
        const addconsultantsdetail = {
            first_name: this.personalDetails.get('first_name').value,
            last_name: this.personalDetails.get('last_name').value,
            year_of_experience: this.personalDetails.get('Totexp').value,
            daily_rate_PKR: this.personalDetails.get('drpkr').value,
            email: this.personalDetails.get('email').value,
            areas: this.areaVals,
            skills: this.skillVals,
            specializations: this.specializationsVals,
            CVslot1: "Email@domain.com",
            CVslot2: "Email@domain.com",
            CVslot3: "Email@domain.com",
            previous_engagement: 0
        }
        this.consultservice.addconsultant(addconsultantsdetail).subscribe((data: any) => {
            this.addconst = data;
            //console.log(this.addconst);
            this.router.navigate(['/dashboard']);
            this.toastr.success('Consultant added successfully', 'Success');
            },
            error => {
                console.log(error);
            }
        );
    }
}
