import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BundleItemsService } from '../../../services/bundle-items.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BundleService } from '../../../services/bundle.service';
import { UserService } from '../../../services/user.service';
import { v4 as uuidv4 } from 'uuid';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { interval } from 'rxjs';
import { WalmartService } from 'src/app/services/walmart.service';

@Component({
  selector: 'app-additemapi',
  templateUrl: './additemapi.component.html',
  styleUrls: ['./additemapi.component.sass']
})
export class AdditemapiComponent implements OnInit {
  categoriesdata: any;
  userdata: any;
  AddItemApi: any;
  form: FormGroup;
  submitted = false;
  floatvalue: any;
  appBaseURL: string;
  AccessToken: any;
  WalmartItem: any;
  DBDATA: any;
  blankimg = '';
  author: any;
  sitestartdate: any;
  siteenddate: any;
  Paramcategory: any;
  Paramupc: any;
  constructor(private walmartService: WalmartService, private http: HttpClient, private userService: UserService, private formBuilder: FormBuilder, private bundleService: BundleService, private toastr: ToastrService, private SpinnerService: NgxSpinnerService, private route: ActivatedRoute, private bundleItemsService: BundleItemsService, private router: Router) { }

  ngOnInit(): void {
    //debugger;
    let userid = localStorage.getItem('user-id');
    if (userid == null || userid == '') {
      this.router.navigate(['/pages/login']);
    }
    this.bundleItemsService.getallcategories().subscribe(async data => {
      this.categoriesdata = data;
      if (this.Paramcategory && this.Paramupc) {
        if (this.Paramupc.length == 14) {
          const str = this.Paramupc.substring(2);
          this.AddItemApi.upc = str;
        }
        else {
          this.AddItemApi.upc = this.Paramupc;
        }
        debugger;
        this.categoriesdata.forEach(DBCategory => {
          this.Paramcategory.forEach(element => {
            if (DBCategory.category == element) {
              this.AddItemApi.category = element;
            }
          });
        });
        setTimeout(() => {
          this.onEnter(this.AddItemApi.upc);
        }, 1000);
      }
    },
      error => {
        console.log(error);
        this.toastr.error('Some error occurred getting all categories!', 'Error!');
      });
    this.route.queryParams.subscribe(params => {
      this.Paramcategory = params['ParamCategory'];
      this.Paramupc = params['ParamUPC'];
    });

    this.blankimg = 'https://i5.walmartimages.com/dfw/63fd9f59-2613/734f8a46-3a3c-4f94-8033-602b36f3c96f/v1/21f0413a83d86c37a05e9992d08ceca6.png';
    this.submitted = false;
    //this.appBaseURL= "http://localhost/Exceltest/";
    this.appBaseURL = "https://api.mitzy.us/Exceltest/";
    this.sitestartdate = new Date().toISOString().slice(0, 10);
    this.siteenddate = new Date(new Date().setFullYear(new Date().getFullYear() + 2)).toISOString().slice(0, 10);
    this.form = this.formBuilder.group({
      upc: ['', Validators.required],
      productName: ['', Validators.required],
      sku: ['', Validators.required],
      brand: ['', Validators.required],
      sitedescription: ['', Validators.required],
      californiawarning: ['', Validators.required],
      mainimgurl: ['', Validators.required],
      orderablegtin: ['', Validators.required],
      eachgtin: ['', Validators.required],
      price: ['', Validators.required],
      restrictiontype: ['', Validators.required],
      category: ['', Validators.required],
      manufacturername: ['', Validators.required],
      esrbrating: '',
      clothingsize: '',
      color: '',
      colorcategory: '',
      gender: '',
      agegroup: '',
      shoesize: '',
      smallpartswarning: '',
      textiles: '',
      labelImageContains: '',
      labelImageURL: '',
      woodcertificationcode: '',
      statecodes: '',
      zipcodes: '',
      countryoforigin: ['', Validators.required],
      depth: ['', Validators.required],
      width: ['', Validators.required],
      height: ['', Validators.required],
      weight: ['', Validators.required],
      electroniccomponent: ['', Validators.required],
      batterytype: ['', Validators.required],
      chemical: ['', Validators.required],
      sitestartdate: ['', Validators.required],
      siteenddate: ['', Validators.required],
    });
    this.AddItemApi = {
      upc: '',
      productName: '',
      sku: '',
      brand: '',
      clothingsize: '',
      color: '',
      colorcategory: '',
      gender: '',
      agegroup: '',
      shoesize: '',
      sitedescription: '',
      californiawarning: '',
      mainimgurl: '',
      orderablegtin: '',
      eachgtin: '',
      price: '',
      restrictiontype: '',
      category: '',
      esrbrating: '',
      manufacturername: '',
      smallpartswarning: '',
      textiles: '',
      labelImageContains: '',
      labelImageURL: '',
      woodcertificationcode: '',
      statecodes: '',
      zipcodes: '',
      countryoforigin: '',
      depth: '',
      width: '',
      height: '',
      weight: '',
      electroniccomponent: '',
      batterytype: '',
      chemical: '',
      sitestartdate: '',
      siteenddate: '',
    };
    //Getting Client ID 
    let userid1 = localStorage.getItem('user-id');
    this.userService.getusersbyid(userid).subscribe(data => {
      this.userService.UserData = data;
      if (this.userService.UserData.Client_ID != '' && this.userService.UserData.Secret_ID != '') {
        const clientid = this.userService.UserData.Client_ID;
        const secretid = this.userService.UserData.Secret_ID;
        this.userService.Authorization = "Basic " + btoa(clientid + ":" + secretid);
        this.GenerateToken();
      }
      else {
        if (this.userService.UserData.Client_ID == '' || this.userService.UserData.Secret_ID == '')
          this.toastr.error('<b>Client_ID</b> or <b>Secret_ID is missing<br>Kindly Go to <b>My Profile</b>', 'Error!', {
            enableHtml: true
          });
      }
    })
    interval(10 * 60 * 1000).subscribe((val) => { this.GenerateToken(); });

  }
  get f() { return this.form.controls; }
  floattodecimal(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };
  pricefloat(num: any) {
    this.floatvalue = parseFloat(num).toFixed(2);
    return this.floatvalue;
  };
  inttofloat(num: any) {
    this.floatvalue = parseFloat(num).toFixed(1);
    return this.floatvalue;
  };
  GenerateToken() {
    const headers = {
      'NAME': 'My API Key',
      'CORRELATION_ID': uuidv4(),
      'Authorization': this.userService.Authorization,
      'Accept': 'application/json'
    };
    const ApiData = {
      path: 'https://marketplace.walmartapis.com/v3/token',
      headers: headers,
      body: 'grant_type=client_credentials'
    }
    this.walmartService.GenerateToken(ApiData).subscribe(data => {
      this.userService.AccessToken = data;
    },
      error => {
        console.log(error);
        this.toastr.error('Some error occurred getting access token!', 'Error!');
      });
    //Old Method
    // const clientid = this.userdata.Client_ID;
    // const secretid = this.userdata.Secret_ID;
    // this.author = "Basic " + btoa(clientid + ":" + secretid);
    // const headers = new HttpHeaders({ 'WM_SVC.NAME': 'My API Key', 'WM_QOS.CORRELATION_ID': uuidv4(), 'Authorization': this.author, 'Content-Type': 'application/x-www-form-urlencoded' });
    // const options = ({ headers: headers });
    // const body = 'grant_type=client_credentials';
    // this.http.post<any>('https://marketplace.walmartapis.com/v3/token', body, options).subscribe(data => {
    //   return this.AccessToken = data.access_token;
    // },
    //   error => {
    //     this.SpinnerService.hide();
    //     if (this.userdata.Client_ID != '' || this.userdata.Secret_ID != '') {
    //       this.toastr.error('Token <b>Not</b> Generated!<br> Kindly Go to <b>My Profile</b><br><b>Update</b> your <b>Client_ID</b> & <b>Secret_ID</b>', 'Error!', {
    //         enableHtml: true
    //       });
    //     }
    //   });
    //Old Method
  }
  onChangeCategory() {
    //debugger;
    const cat = {
      category: this.form.controls.category.value,
    }
    this.bundleItemsService.getdefaultdata(cat).subscribe(
      resdata1 => {
        if (resdata1 != '') {
          //debugger;
          this.DBDATA = resdata1;
          this.AddItemApi.manufacturername = this.DBDATA[0].manufacturername;
          this.AddItemApi.californiawarning = this.DBDATA[0].californiawarning;
          this.AddItemApi.esrbrating = this.DBDATA[0].esrbrating;
          this.AddItemApi.textiles = this.DBDATA[0].textiles;
          this.AddItemApi.labelImageContains = this.DBDATA[0].labelImageContains;
          this.AddItemApi.clothingsize = this.DBDATA[0].clothingsize;
          this.AddItemApi.color = this.DBDATA[0].color;
          this.AddItemApi.colorcategory = this.DBDATA[0].colorcategory;
          this.AddItemApi.gender = this.DBDATA[0].gender;
          this.AddItemApi.agegroup = this.DBDATA[0].agegroup;
          this.AddItemApi.shoesize = this.DBDATA[0].shoesize;
          this.AddItemApi.woodcertificationcode = this.DBDATA[0].woodcertificationcode;
          this.AddItemApi.smallpartswarning = this.DBDATA[0].smallpartswarning;
          this.AddItemApi.restrictiontype = this.DBDATA[0].restrictiontype;
          this.AddItemApi.sitestartdate = this.sitestartdate;
          this.AddItemApi.siteenddate = this.siteenddate;
          this.AddItemApi.depth = this.DBDATA[0].depth;
          this.AddItemApi.width = this.DBDATA[0].width;
          this.AddItemApi.height = this.DBDATA[0].height;
          this.AddItemApi.weight = this.DBDATA[0].weight;
          this.AddItemApi.countryoforigin = this.DBDATA[0].countryoforigin;
          this.AddItemApi.electroniccomponent = this.DBDATA[0].electroniccomponent;
          this.AddItemApi.batterytype = this.DBDATA[0].batterytype;
          this.AddItemApi.chemical = this.DBDATA[0].chemical;

          // this.toastr.success('Data Fetched From DB!', 'Success!');
        }
        else {
          this.toastr.error('Empty Database ( if category selected )', 'Error!');
        }
      },
      error => {
        this.toastr.error('Some error occurred while Fetching Data from Database!', 'Error!');
      });
  }
  onEnter(value: string) {
    //debugger;
    const cat = {
      category: this.form.controls.category.value,
    }
    this.bundleItemsService.getdefaultdata(cat).subscribe(
      resdata1 => {
        if (resdata1 != '') {
          //debugger;
          this.DBDATA = resdata1;
          this.AddItemApi.manufacturername = this.DBDATA[0].manufacturername;
          this.AddItemApi.californiawarning = this.DBDATA[0].californiawarning;
          this.AddItemApi.esrbrating = this.DBDATA[0].esrbrating;
          this.AddItemApi.textiles = this.DBDATA[0].textiles;
          this.AddItemApi.labelImageContains = this.DBDATA[0].labelImageContains;
          this.AddItemApi.clothingsize = this.DBDATA[0].clothingsize;
          this.AddItemApi.color = this.DBDATA[0].color;
          this.AddItemApi.colorcategory = this.DBDATA[0].colorcategory;
          this.AddItemApi.gender = this.DBDATA[0].gender;
          this.AddItemApi.agegroup = this.DBDATA[0].agegroup;
          this.AddItemApi.shoesize = this.DBDATA[0].shoesize;
          this.AddItemApi.woodcertificationcode = this.DBDATA[0].woodcertificationcode;
          this.AddItemApi.smallpartswarning = this.DBDATA[0].smallpartswarning;
          this.AddItemApi.restrictiontype = this.DBDATA[0].restrictiontype;
          this.AddItemApi.sitestartdate = this.sitestartdate;
          this.AddItemApi.siteenddate = this.siteenddate;
          this.AddItemApi.depth = this.DBDATA[0].depth;
          this.AddItemApi.width = this.DBDATA[0].width;
          this.AddItemApi.height = this.DBDATA[0].height;
          this.AddItemApi.weight = this.DBDATA[0].weight;
          this.AddItemApi.countryoforigin = this.DBDATA[0].countryoforigin;
          this.AddItemApi.electroniccomponent = this.DBDATA[0].electroniccomponent;
          this.AddItemApi.batterytype = this.DBDATA[0].batterytype;
          this.AddItemApi.chemical = this.DBDATA[0].chemical;

          // this.toastr.success('Data Fetched From DB!', 'Success!');
        }
        else {
          this.toastr.error('Empty Database ( if category selected )', 'Error!');
        }
      },
      error => {
        this.toastr.error('Some error occurred while Fetching Data from Database!', 'Error!');
      });

    //calling Walmart Item Search
    //Old Method
    // const headers1 = new HttpHeaders({ 'WM_SVC.NAME': 'My API Key', 'WM_QOS.CORRELATION_ID': uuidv4(), 'Authorization': this.author, 'WM_SEC.ACCESS_TOKEN': this.AccessToken, 'Content-Type': 'application/json', 'Accept': 'application/json' });
    // const options1 = ({ headers: headers1 });
    // this.http.get<any>('https://marketplace.walmartapis.com/v3/items/walmart/search?upc=' + value, options1).subscribe(data => {
    //Old Method
    //debugger;
    const headers = {
      'NAME': 'My API Key',
      'CORRELATION_ID': uuidv4(),
      'ACCESS_TOKEN': this.userService.AccessToken,
      'Authorization': this.userService.Authorization,
      'Accept': 'application/json'
    };
    const ApiData = {
      path : 'https://marketplace.walmartapis.com/v3/items/walmart/search?upc='+value,
      headers : headers,
    }
    this.walmartService.WalmartSearchAnitem(ApiData).subscribe(data => {
      this.WalmartItem = data.items[0];
      this.blankimg = this.WalmartItem.images[0].url;
      this.AddItemApi.category = this.form.controls.category.value;
      this.AddItemApi.upc = value;
      this.AddItemApi.productName = this.WalmartItem.title;
      this.AddItemApi.sku = value;
      this.AddItemApi.labelImageURL = this.form.controls.labelImageURL.value;
      this.AddItemApi.brand = this.WalmartItem.brand;
      this.AddItemApi.sitedescription = this.WalmartItem.description;
      this.AddItemApi.mainimgurl = this.WalmartItem.images[0].url;
      this.AddItemApi.statecodes = this.form.controls.statecodes.value;
      this.AddItemApi.zipcodes = this.form.controls.zipcodes.value;
      this.AddItemApi.orderablegtin = '00' + value;
      this.AddItemApi.eachgtin = '00' + value;
      this.AddItemApi.price = (this.floattodecimal(this.WalmartItem.price.amount, 2)).toString();

    });
  }
  onSubmitFN() {
    this.submitted = true;
    if (this.form.invalid) {
      this.toastr.error('Form, invalid.', 'Error');
      return;
    }
    else {
      debugger;
      const newdata = {
        //author:this.author,
        Client_ID: this.userdata.Client_ID,
        category: this.AddItemApi.category,
        upc: this.AddItemApi.upc,
        productName: this.AddItemApi.productName,
        sku: this.AddItemApi.sku,
        manufacturername: this.AddItemApi.manufacturername,
        brand: this.AddItemApi.brand,
        sitedescription: this.AddItemApi.sitedescription,
        californiawarning: this.AddItemApi.californiawarning,
        smallpartswarning: this.AddItemApi.smallpartswarning,
        esrbrating: this.AddItemApi.esrbrating,
        textiles: this.AddItemApi.textiles,
        labelImageContains: this.AddItemApi.labelImageContains,
        clothingsize: this.AddItemApi.clothingsize,
        color: this.AddItemApi.color,
        shoesize: this.AddItemApi.shoesize,
        colorcategory: this.AddItemApi.colorcategory,
        gender: this.AddItemApi.gender,
        agegroup: this.AddItemApi.agegroup,
        labelImageURL: this.AddItemApi.labelImageURL,
        woodcertificationcode: this.AddItemApi.woodcertificationcode,
        mainimgurl: this.AddItemApi.mainimgurl,
        price: this.pricefloat(this.AddItemApi.price),
        restrictiontype: this.AddItemApi.restrictiontype,
        statecodes: this.AddItemApi.statecodes,
        zipcodes: this.AddItemApi.zipcodes,
        orderablegtin: this.AddItemApi.orderablegtin,
        eachgtin: this.AddItemApi.eachgtin,
        depth: this.inttofloat(this.AddItemApi.depth),
        width: this.inttofloat(this.AddItemApi.width),
        height: this.inttofloat(this.AddItemApi.height),
        weight: this.inttofloat(this.AddItemApi.weight),
        countryoforigin: this.AddItemApi.countryoforigin,
        electroniccomponent: this.AddItemApi.electroniccomponent,
        batterytype: this.AddItemApi.batterytype,
        chemical: this.AddItemApi.chemical,
        sitestartdate: this.AddItemApi.sitestartdate,
        siteenddate: this.AddItemApi.siteenddate,
        userid: localStorage.getItem('user-id'),
      };
      //debugger;
      if (this.userdata.Client_ID == "023a1a9c-8696-49bb-a11a-158832080c4a") {
        this.bundleItemsService.defaultdatainsert(newdata).subscribe(
          resdata => {
            // this.SpinnerService.hide();
            // this.toastr.success(resdata, 'Success!');
          },
          error => {
            this.toastr.error('Error occurred while Inserting/Updating Default Data!', 'Error!');
          });
      }

      this.http.post<any>(this.appBaseURL + "/walmartitemapi.php", newdata).subscribe(
        (res: any) => {
          this.toastr.success('Item Sent to Walmart', 'Success!');
          this.ngOnInit();
        },
        error => {
          this.toastr.success('Item Sent to Walmart<br><b><u><a href="https://seller.walmart.com/items-and-inventory/feed-status">Check Item Activity Feed from Website</a></u></b>', 'Success!', {
            enableHtml: true
          });
          this.ngOnInit();
        });
    }
  }

}
