import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.sass']
})
export class ResetPasswordComponent implements OnInit {

    public form: FormGroup;
    public Password2 = '';
    public Password = '';
    public email = "";
    public guid = "";
    //submitted = false;
    appBaseURL: string;

    constructor(private userservice: UserService,private route: ActivatedRoute, private formBuilder: FormBuilder, private router: Router, private http: HttpClient, private toastr: ToastrService) {
        //debugger;
        this.route.queryParams.subscribe(params => {
            this.email = params['email'];
            this.guid = params['guid'];
        });
    }

    ngOnInit() {
        this.appBaseURL = document.location.origin + "/";
        var index = this.appBaseURL.indexOf('localhost:4200', 0);
        if (index > -1) {
            this.appBaseURL = "http://localhost:3000/";
        }

        this.form = this.formBuilder.group({
            password2: ['', [Validators.required, Validators.minLength(6)]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
    }
    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        //debugger;
        //this.submitted = true;
        if (this.form.invalid && this.guid != "" && this.email != "") {
            return;
        }
        else {
            const resetpass = {
                email: this.email,
                guid: this.guid,
                new_password: this.form.value.password
            }
            this.userservice.resetpassword(resetpass).subscribe((data: any) => {
                //debugger;
                //console.log(data.message);
                if (data == "") {
                    this.toastr.error('Invalid login, please try with valid credentials.', 'Error');
                } else {
                   
                    this.toastr.success(data.message, 'Success');
                    this.router.navigate(['/pages/login']);
                }
            })
        }
    }

}
