import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class BundleItemsService {

    appBaseURL: string;

    constructor(private http: HttpClient) {
        this.appBaseURL = document.location.origin + "/";
        var index = this.appBaseURL.indexOf('localhost:4200', 0);
        if (index > -1) {
            this.appBaseURL = "http://localhost:3000/";
        }
    }

    GetAllItemsByBundleId(id): Observable<any> {
        return this.http.post(this.appBaseURL + 'getbundleitemsbyid/' + id, "");
    }
    getbundleorder(id): Observable<any> {
      return this.http.post(this.appBaseURL + 'getbundleorder/' + id, "");
  }
    
      create(data): Observable<any> {
        return this.http.post(this.appBaseURL +'addbundleitems/', data);
      }

      StoreUserLogs(id): Observable<any> {
        return this.http.post(this.appBaseURL + 'storeuserlogs/' + id, "");
    }
    delete(id): Observable<any> {
      return this.http.post(this.appBaseURL +'deletebundleitems/'+id,"");
    }
    defaultdatainsert(data): Observable<any> {
      return this.http.post(this.appBaseURL +'defaultdatainsert/', data);
    }
    getdefaultdata(data): Observable<any> {
      return this.http.post(this.appBaseURL +'getdefaultdata/', data);
    }
    getallcategories(): Observable<any> {
      return this.http.post(this.appBaseURL +'getallcategories/', "");
    }

    // sendtowalmart(newdata): Observable<any> {
    //   return this.http.post("http://localhost/Exceltest/walmartitemapi.php" , newdata);
    // }
   
     
}
