import { Component, OnInit } from '@angular/core';
import { SettingService } from '../../../services/settings.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  websiteName: string;
  websitecopyrighttext: SafeHtml;

  constructor(private settingService: SettingService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    const websiteName = {
      code: "website-name"
    }

    this.settingService.getbycode(websiteName).subscribe((data: any) => {
      //debugger;
      this.websiteName = data[0].message;
      localStorage.setItem("website-name", data[0].message);

    })
    const websitecopyrighttext = {
      code: "website-copyright-text"
    }
    this.settingService.getbycode(websitecopyrighttext).subscribe((data: any) => {
      //debugger;
      this.websitecopyrighttext = this.sanitizer.bypassSecurityTrustHtml(data[0].message + ' - ' + this.websiteName)
    })

  }


}
