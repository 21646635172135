import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingService } from '../../../services/settings.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-editsettings',
  templateUrl: './editsettings.component.html',
  styleUrls: ['./editsettings.component.sass']
})
export class EditsettingsComponent implements OnInit {
  submitted = false;
  currentSetting: any;
  message = '';

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(private SpinnerService: NgxSpinnerService, private settingService: SettingService, private route: ActivatedRoute,
    private router: Router,private toastr: ToastrService) { }

  ngOnInit(): void {

    this.message = '';
    this.getSetting(this.route.snapshot.paramMap.get('id'));
    let userid = localStorage.getItem('user-id');
        if (userid == null || userid == '') {
            this.router.navigate(['/pages/login']);
        }
  }

  getSetting(id): void {
    //debugger;

    this.SpinnerService.show();
    this.settingService.get(id)
      .subscribe(
        data => {
          this.SpinnerService.hide();
          this.currentSetting = data;
        },
        error => {
          console.log(error);
        });
  }
  updateSetting(id, currentSetting): void {
    this.SpinnerService.show();
    let modified_by = localStorage.getItem('user-id');
    this.currentSetting.modified_by=modified_by;
    this.settingService.update(id,currentSetting)
      .subscribe(
        response => {
          this.SpinnerService.hide();
          this.router.navigate(['/settings']);
          this.toastr.success('Setting updates successfully!', 'Success!');
        },
        error => {
          console.log(error);
          this.toastr.error('Some error occurred!', 'Error!');
        });
  }
}
