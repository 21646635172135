import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsultantService {
    appBaseURL: string;

    constructor(private http: HttpClient) {
        this.appBaseURL = document.location.origin + "/";
        var index = this.appBaseURL.indexOf('localhost:4200', 0);
        if (index > -1) {
            this.appBaseURL = "http://localhost:3000/";
        }
    }
    getallarea(data): Observable<any> {
        return this.http.post(this.appBaseURL + 'GetAllActiveAreas', data);
    }
    getallskill(skilldata): Observable<any> {
        return this.http.post(this.appBaseURL + 'GetSkillsAllActive', skilldata);
    }
    getallspeclize(id,specdata): Observable<any> {
        return this.http.post(this.appBaseURL + 'GetAreaSpecializationsAllActive/' + id, specdata);
    }

    addconsultant(consultdata): Observable<any> {
        //debugger;
        return this.http.post(this.appBaseURL + 'Addconsultant', consultdata);
    }
}
