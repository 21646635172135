import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass']
})
export class ChangePasswordComponent implements OnInit {

    public form: FormGroup;
    submitted = false;

    constructor(private userservice: UserService, private formBuilder: FormBuilder, private router: Router, private toastr: ToastrService) {
    }
    get f() { return this.form.controls; }

    onSubmit() {
        //debugger;
        this.submitted = true;

        if (this.form.invalid) {
            return;
        }
        else {
            const logindata = {
                id: localStorage.getItem('user-id'),
                old_password: this.form.controls.old_password.value,
                new_password: this.form.controls.new_password.value
            }
            this.userservice.ChangePassword(logindata).subscribe((data: any) => {
                //debugger;
                if (data == "") {
                    this.toastr.error('Invalid password, please try with valid password.', 'Error');
                } else {
                    this.toastr.success('Your password changed successfully', 'Good Job!');
                    this.router.navigate(['/dashboard']);
                }
            })
        }
    }

    ngOnInit(): void {
        //debugger;
        //check login
        let userid = localStorage.getItem('user-id');
        if (userid == null || userid == '') {
            this.router.navigate(['/pages/login']);
        }

        this.form = this.formBuilder.group({
            old_password: ['', [Validators.required, Validators.minLength(6)]],
            new_password: ['', [Validators.required, Validators.minLength(6)]],
            confirm_password: ['', [Validators.required, Validators.minLength(6)]]
        });
        //console.log(this.form);
  }

}
