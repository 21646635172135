import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
@Injectable({
  providedIn: 'root'
})
export class WalmartService {
  appBaseURL: string;
  constructor(private http: HttpClient,private userService: UserService) {
    this.appBaseURL = document.location.origin + "/";
    var index = this.appBaseURL.indexOf('localhost:4200', 0);
    if (index > -1) {
      this.appBaseURL = "http://localhost:3000/";
    }
  }
  
  GenerateToken(ApiData): Observable<any> {
    return this.http.post(this.appBaseURL + 'generatetoken', ApiData);
  }
  WalmartSearchAnitem(ApiData): Observable<any> {
    return this.http.post(this.appBaseURL + 'walmartsearchanitem', ApiData);
  }
  SellerSearchAnitem(ApiData): Observable<any> {
    return this.http.post(this.appBaseURL + 'sellersearchanitem', ApiData);
  }
  get(id): Observable<any> {
    return this.http.post(this.appBaseURL + 'getwfsrequiredbyid/' + id, "");
  }


  //TokenCalling

}
