import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WfsrequiredService } from '../../../services/wfsrequired.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-editwfsrequired',
  templateUrl: './editwfsrequired.component.html',
  styleUrls: ['./editwfsrequired.component.sass']
})
export class EditwfsrequiredComponent implements OnInit {
  wfsrequireddata:any;
  submitted = false;
  constructor(private SpinnerService: NgxSpinnerService,private WfsRequired: WfsrequiredService, private route: ActivatedRoute,
    private router: Router,private toastr: ToastrService) { }

  ngOnInit(): void {
    //debugger;
    this.getwfsrequiredbyid(this.route.snapshot.paramMap.get('id'));
    let userid = localStorage.getItem('user-id');
        if (userid == null || userid == '') {
            this.router.navigate(['/pages/login']);
        }
  }
  getwfsrequiredbyid(id): void {
    this.SpinnerService.show();
    this.WfsRequired.get(id)
      .subscribe(
        data => {
          this.SpinnerService.hide();
          this.wfsrequireddata = data;
        },
        error => {
          console.log(error);
        });
  }
  updatewfsrequired(wfsrequireddata): void {
    this.SpinnerService.show();
   
        //debugger;
        this.SpinnerService.show();
        this.WfsRequired.update(wfsrequireddata).subscribe(
        resdata => {
          this.SpinnerService.hide();
          this.router.navigate(['/wfsrequired']);
          this.toastr.success(resdata, 'Success!');
        },
        error => {
          this.SpinnerService.hide();
          this.toastr.error('Error occurred while Inserting/Updating Default Data!', 'Error!');
        });
    
  }
}
