import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from '../../../services/settings.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
  selector: 'app-addsettings',
  templateUrl: './addsettings.component.html',
  styleUrls: ['./addsettings.component.sass']
})
export class AddsettingsComponent implements OnInit {
  name = 'Angular 6';
  htmlContent = '';

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  
  setting = {
    
    code: '',
    message: '',
    created_by: localStorage.getItem("user-id"),
    modified_by: localStorage.getItem("user-id"),
  };
  submitted = false;
  currentSetting = null;
  message = '';
  
  constructor(private SpinnerService: NgxSpinnerService,private settingService: SettingService, private route: ActivatedRoute,
    private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getSetting(this.route.snapshot.paramMap.get('id'));
    let userid = localStorage.getItem('user-id');
        if (userid == null || userid == '') {
            this.router.navigate(['/pages/login']);
        }
  }
  getSetting(id): void {
    this.SpinnerService.show();
    this.settingService.get(id)
      .subscribe(
        data => {
          this.SpinnerService.hide();
          this.currentSetting = data;
        },
        error => {
          this.SpinnerService.hide();
          console.log(error);
        });
  }
  updateSetting(): void {
    this.SpinnerService.show();
    this.settingService.update(this.currentSetting.id, this.currentSetting)
      .subscribe(
        response => {
          this.SpinnerService.hide();
          this.submitted = true;
          this.router.navigate(['/settings']);
          this.toastr.success('Setting updates successfully!', 'Success!');
        },
        error => {
          this.SpinnerService.hide();
          console.log(error);
          this.toastr.error('Some error occurred!', 'Error!');
        });
  }
  saveSetting(): void {
    const data = {
      code: this.setting.code,
      message: this.setting.message,
      created_by: this.setting.created_by,
      modified_by: this.setting.modified_by,
    };
    this.SpinnerService.show();
    this.settingService.create(data)
      .subscribe(
        response => {
          this.SpinnerService.hide();
          this.submitted = true;
          this.router.navigate(['/settings']);
          this.toastr.success('Setting added successfully!', 'Success!');
        },
        error => {
          this.SpinnerService.hide();
          console.log(error);
          this.toastr.error('Some error occurred!', 'Error!');
        });
  }

  newSetting(): void {
    this.submitted = false;
    this.setting = {
      code: '',
      message: '',
      created_by: localStorage.getItem("user-id"),
      modified_by: localStorage.getItem("user-id"),
    };
  }
  
}
