import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BundleService } from '../../../../services/bundle.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-add-bundles',
  templateUrl: './add-bundles.component.html',
  styleUrls: ['./add-bundles.component.sass']
})
export class AddBundlesComponent implements OnInit {
  name = 'Angular 6';
  htmlContent = '';


  
  bundles = {
    //inbound_order_number: '',
    expected_delievery_date: '22/09/2021',
    user_id	: localStorage.getItem("user-id"),
  };
  submitted = false;
  currentBundles = null;
  expected_delievery_date = '';
  today: string;
  dt = new Date();


  constructor(private SpinnerService: NgxSpinnerService,private bundleService: BundleService, private route: ActivatedRoute,
    private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    let userid = localStorage.getItem('user-id');
        if (userid == null || userid == '') {
            this.router.navigate(['/pages/login']);
        }
    this.getBundles(this.route.snapshot.paramMap.get('id'));
        this.dt.setDate(this.dt.getDate()+5);
        this.today=this.dt.toISOString().split('T')[0];


  }
  
  getBundles(id): void {
    this.SpinnerService.show();
    this.bundleService.get(id)
      .subscribe(
        data => {
          this.SpinnerService.hide();
          this.currentBundles = data;
        },
        error => {
          this.SpinnerService.hide();
          console.log(error);
        });
  }
 
  saveBundles(): void {
    const data = {
      
      //inbound_order_number: '548',//this.bundles.inbound_order_number,
      expected_delievery_date: this.today,
      user_id: localStorage.getItem("user-id"),
    };
    this.SpinnerService.show();
    this.bundleService.create(data)
      .subscribe(
        response => {
          this.SpinnerService.hide();
          this.submitted = true;
          this.toastr.success('Order added successfully!', 'Success!');
          this.router.navigate(['/my-orders']);
        },
        error => {
          this.SpinnerService.hide();
          console.log(error);
          this.toastr.error('Some error occurred!', 'Error!');
        });
  }
}